// src/mixins/conversationsMixin.js
import { mapGetters } from 'vuex';
import Axios from 'axios';

export default {
  computed: {
    ...mapGetters({
      authUser: 'auth/currentUser'
    })
  },
  methods: {
    getConversation (recipientId, senderId, callback) {
      const metadata = [recipientId, senderId].sort((a, b) => {
        if (a >= b) {
          return 1;
        }
        return -1;
      }).join(',');
      // TODO - improve conversation filtering!
      const url = `${process.env.VUE_APP_BASE_URL}/api/conversations?search=${metadata}`;
      Axios
        .get(url)
        .then((response) => {
          if (!response.data.data.length) {
            throw new Error(this.$t('errorLoadingConversation'));
          } else {
            callback(response.data.data[0].id);
          }
        })
        .catch((error) => {
          this.showError(error);
        });
    },
    createConversation (userId, userName) {
      if (!this.currentUser) {
        this.$swal.fire({ title: this.$t('accessContentNeedLogIn') });
        this.$router.push({ name: 'auth1.sign-in1' });
      } else {
        const url = `${process.env.VUE_APP_BASE_URL}/api/conversations`;
        const data = {
          name: userName,
          user_ids: [this.currentUser.id, userId]
        };

        Axios
          .post(url, data)
          .then((res) => {
            this.$router.push({
              name: 'app.chat',
              params: { conversationId: res.data.data[0].id.toString() }
            });
          })
          .catch((error) => {
            if (error.response.data.errors.user_ids && error.response.data.errors.user_ids[0] === 'conversationAlreadyCreated') {
              this.getConversation(
                  userId,
                  this.currentUser.id,
                  (conversationId) => {
                    this.$router.push({
                      name: 'app.chat',
                      params: { conversationId: conversationId.toString() }
                    });
                  }
              )
            } else {
              this.showError(error);
            }
          });
      }
    }
  }
};
